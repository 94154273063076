import React from "react"
import OneTrustContent from "../components/OneTrustContent";

export default ()=>(
  <OneTrustContent 
    id="otnotice-5ae2fcea-e2fc-4415-a213-3e9a332cfab8"
    url="https://privacyportal-cdn.onetrust.com/722df920-f42e-4d91-b843-6306f9a6a5a0/privacy-notices/5ae2fcea-e2fc-4415-a213-3e9a332cfab8.json"
    title="Privacy"
  />
);
